import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import pocketzero from "../../assets/device-icons/pocketzero.png";
import storepay from "../../assets/device-icons/storepay.png";
import imageIcon from "../../assets/icons/images.png";
import { Select, Tabs, notification } from "antd";
import ToolbarSecond from "../../ToolbarSecond";
import battery_banner from "../../assets/others/battery_banner.png";
import Header from "../../Header";
import Gallery from "../SliderGallery";
import AdditionalList from "./AdditionalList";
import { products } from "../Dummy";
import fb_black from "../../assets/icons/7_black.png";
import insta_black from "../../assets/icons/8_black.png";

const BuyFlight = () => {
  const { id } = useParams();
  const [tabPosition] = useState("bottom");

  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(1);
  const [addItems, setAddItem] = useState([]);
  const [price, setPrice] = useState(products[id - 1].price);
  const [name, setName] = useState(products[id-1].name);
  const [totalPrice, setTotalPrice] = useState(products[id - 1].price);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedAdditionalItems, setSelectedAdditionalItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const handleFbClick = () => {
    window.open("https://www.facebook.com/starlinknomad", "_blank");
  };
  const handleInstaClick = () => {
    window.open("https://www.instagram.com/starlinknomad/", "_blank");
  };

  const callInstruction = () => {
    if (isVisible === true) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const handleChange = (value) => {
    if (value === "2") {
      setPrice(6000000);
    } else setPrice(6500000);
  };
  useEffect(() => {
    const productId = Number(id);
    const oneProduct = products.find((p) => p.id === productId);
    if (oneProduct) {
      setSelectedProduct(oneProduct);
      const filteredProducts = products.filter(
        (item) =>
          oneProduct.additionalBuyDetail &&
          oneProduct.additionalBuyDetail.includes(item.id)
      );
      if (filteredProducts) {
        setAddItem(filteredProducts);
      }
    } else {
      navigate("/some-error-page");
    }
  }, [id]);

  const setTabs = (item) => { 
    setSelectedTab(item);
  };

  const handleSelect = (item) => {
    setSelectedItem(item.id);

    let updatedSelectedItems;
    if (
      selectedAdditionalItems.some(
        (selectedItem) => selectedItem.id === item.id
      )
    ) {
      updatedSelectedItems = selectedAdditionalItems.filter(
        (selectedItem) => selectedItem.id !== item.id
      );
    } else {
      updatedSelectedItems = [...selectedAdditionalItems, item];
    }

    setSelectedAdditionalItems(updatedSelectedItems);

    const newTotalPrice = updatedSelectedItems.reduce(
      (sum, selectedItem) => sum + selectedItem.price,
      0
    );
    setTotalPrice(newTotalPrice + price);
  };

  const updateCartCount = (newCount) => {
    setCartCount(newCount);
  };

  const addShoppingCard = () => {
    const cartItems = [products[id - 1], ...selectedAdditionalItems];
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    updateCartCount(cartItems.length);
    notification.success({
      message: "Сагсанд нэмэгдлээ.",
      description: "",
      duration: 3,
    });
  };

  console.log("selected: ", selectedAdditionalItems);
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    setIsBlinking(true);
    const timer = setTimeout(() => {
      setIsBlinking(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const tabItems = [
    {
      label: (
        <div
          className={`bs-label ${selectedTab !== 0 ? "" : "selected"}`}
          onClick={() => setTabs(0)}
        >
          <div className="bs-label-img-cont">
            {selectedTab === 0 ? (
              <img className="starlink-icon" src={imageIcon} alt="dd"></img>
            ) : (
              <img
                className="starlink-icon"
                src="https://icons.iconarchive.com/icons/iconsmind/outline/256/Photos-icon.png"
                alt="ll"
              ></img>
            )}
          </div>
          <span>Photos</span>
        </div>
      ),
      key: "1",
      children: (
        <div style={{ padding: 20 }}>
          <div className="bs-tab-content">
            <div className="starlink-icon">
              {selectedProduct.id < 60 ? (
                <Gallery item={id}></Gallery>
              ) : (
                <img
                  className="starlink-icon"
                  src={selectedProduct.img}
                  alt="dd"
                ></img>
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: (
        <div
          className={`bs-label ${selectedTab === 1 ? "selected" : ""}`}
          onClick={() => setTabs(1)}
        >
          <div className="bs-label-img-cont">
            {selectedTab === 1 ? (
              <img className="starlink-icon" src={imageIcon} alt="dd"></img>
            ) : (
              <img
                className="starlink-icon"
                src="https://www.iconpacks.net/icons/1/free-video-icon-818-thumb.png"
                alt="dd"
              ></img>
            )}
          </div>
          <span>Video</span>
        </div>
      ),
      key: "2",
      children: (
        <div style={{ padding: 20 }}>
          <div className="bs-tab-content ">
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${products[id - 1].video}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          marginBottom:300
        }}
        className="flex flex-col min-h-screen"
      >
        <Header></Header>
        <ToolbarSecond></ToolbarSecond>
        <div className="href-cont">
          <div
            className="href-text "
            style={{ marginLeft: -30, marginTop: 90 }}
          >
            <span onClick={() => navigate(`/home`)} className="l-menu">
              Нүүр{" "}
            </span>
            <span onClick={() => navigate(`/buy-list`)} className="l-menu">
              / Худалдаж авах
            </span>
            <span> / {selectedProduct.name}</span>
          </div>
        </div>
        <div className="bs-container ">
          <div className="bf-container-tabs">
            <Tabs
              className="tab-height"
              tabPosition={tabPosition}
              items={tabItems}
              centered
            />
          </div>

          <div className="bs-second-container">
            <div
              className="bs-second-container-header"
              style={{ marginTop: 20 }}
            >
              {name}
            </div>
            {id === "5" && (
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Select
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    border: "2px solid blue",
                    borderRadius: 5,
                    color: "blue",
                  }}
                  className={isBlinking ? "blinking" : ""}
                  defaultValue="Cайжруулсан буюу WiFi 6 утасгүй сүлжээ цацагч, 1ш LAN
                  гаргалттай загвар"
                >
                  <Select.Option value="1">
                    Cайжруулсан буюу WiFi 6 утасгүй сүлжээ цацагч, 1ш LAN
                    гаргалттай загвар
                  </Select.Option>
                  <Select.Option value="2">
                    Стандарт буюу WiFi 5 утасгүй сүлжээ цацагч LAN гаргалтгүй
                    загвар
                  </Select.Option>
                </Select>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="bs-header ">
                MNT {price && price.toLocaleString()}
              </div>
              <div className=" display-row " style={{ marginLeft: 20 }}>
                <div className="bs-icon">
                  <img
                    className="i-cs br-10 br-grey"
                    src={storepay}
                    alt="store"
                  ></img>
                </div>
                <div className="bs-icon margin-left-5">
                  <img
                    alt="pocket"
                    className="starlink-icon br-10"
                    src={pocketzero}
                  ></img>
                </div>
                <div
                  style={{
                    marginLeft: 10,
                    fontSize: 12,
                  }}
                >
                  хуваан төлөх боломжтой
                </div>
              </div>
            </div>
            <div className="bs-header-text">
              <div className="color-3b3e40 display-row">
                <ul className="font-size-18 margin-top-5">
                  <li>{products[id - 1].headerText}</li>
                </ul>
              </div>
            </div>
            <div>
              <h4 className="margin-top-40 margin-bottom-20">НЭМЭЛТЭЭР</h4>
              <div className="bs-check-grid">
                {addItems.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="bf-select-item"
                      key={index}
                      onClick={() => handleSelect(item)}
                    >
                      <div
                        className={`bf-col-1 ${
                          selectedAdditionalItems.some(
                            (selectedItem) => selectedItem.id === item.id
                          )
                            ? "clicked"
                            : ""
                        }`}
                      >
                        <div className="bs-check-cont">
                          <img
                            alt={item.name}
                            className="starlink-icon"
                            src={item.img}
                          />
                        </div>
                        <div className="bs-check-cont-2">
                          <p className="bs-check-cont-2-name">{item.name}</p>
                          <p className="bs-check-cont-2-amount">
                            MNT {item.price.toLocaleString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mr-30">
          <hr className="bs-hr" />
        </div>

        <AdditionalList></AdditionalList>
        <div className="mr-30">
          <hr className="bs-hr" />
        </div>
        <div className="bs-s-cont">
          <div className="display-column margin-left-40" style={{ width: 950 }}>
            <h3 className="bs-s-header ">
              {products[id - 1].descMain
                ? products[id - 1].descMain
                : products[0].descMain}
            </h3>
            <div className="bs-s-desc">
              {products[id - 1].descriptionObject.map((item, index) => (
                <div key={index} className="margin-top-5 font-size-18">
                  <div>
                    <span className="bs-s-dot"></span>
                    <span>{item.value}</span>
                  </div>
                  {item.subValue && (
                    <div className="sub-values">
                      {item.subValue.map((i, k) => (
                        <div key={k}>
                          <span style={{ marginLeft: 40 }}>{i}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            {(id === "1" || id === "5") && (
              <div className="tech">
                <span className="bs-s-dot"></span>
                Техникийн үзүүлэлттэй{" "}
                <a href={`/home?id=${"starlink"}`}>энд дарж </a>танилцана уу.
              </div>
            )}
          </div>
          <div className="add-c-1">
            {products[id - 1].accessories.length > 0 && (
              <div
                style={{
                  padding: 20,
                  border: "1px solid grey",
                  height: "80%",
                  width: 320,
                }}
              >
                <>
                  <h3 className="bs-s-sub-header ">Дагалдах зүйлс</h3>
                  {products[id - 1].accessories.map((item, index) => (
                    <div style={{ listStyleType: "circle" }}>
                      <span className="bs-s-dot"></span>
                      <span style={{ fontSize: 14 }}>{item}</span>
                    </div>
                  ))}
                </>
              </div>
            )}
          </div>
        </div>
        {id !== "2" && (
          <div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div className="bf-row-1">
                <div className="bs-row-1-cont">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/Z91aGWm7JYs?si=jYBh6jzI60r3f6yr"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div className="bs-video-cont" style={{ marginTop: 10 }}>
                <div className="bs-video-cont-img-c">
                  <img
                    src={products[id - 1].img1}
                    className="bs-video-cont-img-1"
                    alt="Tab 2 Content"
                  />
                </div>
                <div className="bs-video-cont-img-d">
                  <img
                    src={products[id - 1].img2}
                    className="bs-video-cont-img"
                    alt="Tab 2 Content"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {id === "2" && (
        <div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div className="bf-row-1">
              <div className="bs-row-1-cont">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/ZpIKs1N0WFA?si=3tOrHiWkdHmETyX_"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="bf-row-1" style={{ marginBottom: 40, marginTop: 10 }}>
            <div className="bs-row-1-cont" style={{ border: "1px solid grey" }}>
              <img src={battery_banner} className="starlink-icon" alt="b2" />
            </div>
          </div>
        </div>
      )}
      <div className="buy-button-container " >
        <div className="fl-container" >
          <div className="fl-sub">
            <div className="fl-sub-1 ">
              {/* <div className="fl-img">
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={b_long}
                ></img>
              </div> */}
              <div
                className="fl-txt "
                style={{ marginLeft: -10 }}
                onClick={() => navigate(`/home?id=${"starlink3"}`)}
              >
                Түгээмэл асуултууд
              </div>
              <div className="fl-txt " onClick={() => navigate("/contact")}>
                Холбоо барих
              </div>
              <div className="fl-txt" onClick={callInstruction}>
                Заавар татах
              </div>
              {isVisible && (
                <div
                  className="fl-txt"
                  onClick={() =>
                    window.open("./assets/pdf/MobileBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Хөдөлгөөнт идэвхжүүлэх
                </div>
              )}
              {isVisible && (
                <div
                  className="fl-txt"
                  onClick={() =>
                    window.open("./assets/pdf/SuurinBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Суурин идэвхжүүлэх
                </div>
              )}
              <div
                className="fl-sub-2"
                // style={{ marginLeft: 40, marginTop: 5 }}
              >
                <div className="fl-imgg">
                  <img
                    className="fl-img-2"
                    src={fb_black}
                    onClick={() => handleFbClick()}
                  ></img>
                </div>
                <div className="fl-imgg">
                  <img
                    className="fl-img-3"
                    onClick={() => handleInstaClick()}
                    src={insta_black}
                  ></img>
                </div>
              </div>
            </div>

            <div className="bs-price-cont ">
              <span
                className="bs-pric-txt"
                style={{
                  color: totalPrice > 0 ? "red" : "black",
                }}
              >
                MNT {totalPrice && totalPrice.toLocaleString()}
              </span>
              <button className="bs-button2" onClick={addShoppingCard}>
                ХУДАЛДАЖ АВАХ
              </button>
            </div>
          </div>
          <div className="fl-hr">
            <hr className="fl-hr-s" />
          </div>
          <div className="fl-cont-2">
            <div className="fl-add">
              Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503
              тоот(Циркийн урд)
            </div>
            <div className="fl-phone" style={{ marginRight: 5 }}>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyFlight;
