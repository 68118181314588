import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./auth";
import { AppRoutes } from './AppRoutes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <AuthProvider>
        <AppRoutes />
      </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
