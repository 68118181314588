import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import gen2 from "../../assets/img/starlink_gen2.png";
import stants from "../../assets/others/yavdag_stants.png";
import Header from "../../Header";
import ToolbarSecond from "../../ToolbarSecond";

import ic_down from "../../assets/icons/ic_arrow_down_gray.png";
import FooterL from "../../Footer";
import { Radio } from "antd";
import { products } from "../Dummy";

const AllProduct = () => {
  const { id } = useParams();
  const fProducts = products.filter((item) => item.id !== 99);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(1);
  const [selectedItems, setSelectedItems] = useState(fProducts);
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [selectedRadioValue, setSelectedRadioValue] = useState(1);
  const [type, setType] = useState(null);
  const [v, setV] = useState(12);
  const [isSelected, setIsSelected] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const filterMenuItem = [
    {
      id: 1,
      name: "Интернэт сүлжээний",
      img: gen2,
    },
    {
      id: 2,
      name: "Цэнэг хураагуурын",
      img: stants,
    },
  ];

  const category = [
    {
      id: 1,
      items: [
        {
          id: 1,
          name: "Хөдөлгөөнт интернэт",
        },
        {
          id: 2,
          name: "Суурин интернэт",
        },
        {
          id: 3,
          name: "WiFi тэлэгч",
        },
      ],
    },
    {
      id: 1,
      items: [
        {
          id: 1,
          name: "(All in One) зөөврийн",
        },
        {
          id: 2,
          name: "Сууриллуулдаг",
        },
      ],
    },
    {
      id: 2,
      name: "Цэнэг хураагуурын",
    },
  ];
  const filterMenuItem2 = [
    {
      id: 1,
      name: "Үндсэн төхөөрөмжүүд",
      img: gen2,
    },
    {
      id: 2,
      name: "Нэмэлт хэрэгслүүд",
      img: stants,
    },
  ];

  const mainfilter = [
    "Санал болгосон",
    "Хамгийн их авдаг",
    "Шинээр нэмэгдсэн",
    "Үнэ өсөхөөр",
    "Үнэ буурахаар",
  ];

  const changeItems = (id) => {
    setSelectedItem(id);
    const filteredItems = products.filter((item) => item.category === id);
    if (filteredItems) {
      setSelectedItems(filteredItems);
    }
  };
  const onRadioChange = (e) => {
    console.log("radio checked: ", e.target.value);
    setSelectedRadioValue(e.target.value);
    const filteredItems = products.filter(
      (item) => item.category === e.target.value
    );
    if (filteredItems) {
      setSelectedItems(filteredItems);
    }
  };
  const changeType = (e) => {
    setType(e);
    const filteredItems = products.filter((item) => item.mainProduct === e);
    if (filteredItems) {
      setSelectedItems(filteredItems);
    }
  };
  const changeV = (e) => {
    console.log(e);
    setV(e);
    const filteredItems = products.filter(
      (item) => item.batteryType === e || item.batteryType === 3
    );
    if (filteredItems) {
      setSelectedItems(filteredItems);
    }
  };

  const setSelectMainFilter = (index) => {
    setIsSelected(index);
    if (index === 1) {
      const sortedItems = products.sort((a, b) => b.mostBuy - a.mostBuy);
      if (sortedItems) {
        setSelectedItems(sortedItems);
      }
    } else if (index === 2) {
      const sortedItems1 = products.sort((a, b) => b.addNew - a.addNew);
      const sortedItems = sortedItems1.filter((item) => item.id !== 99);
      if (sortedItems) {
        setSelectedItems(sortedItems);
      }
    } else if (index === 4) {
      const sortedItems1 = products.sort((a, b) => b.price - a.price);
      const sortedItems = sortedItems1.filter((item) => item.id !== 99);
      if (sortedItems) {
        setSelectedItems(sortedItems);
      }
    } else if (index === 3) {
      const sortedItems1 = products.sort((a, b) => a.price - b.price);
      const sortedItems = sortedItems1.filter((item) => item.id !== 99);
      if (sortedItems) {
        setSelectedItems(sortedItems);
      }
    } else {
      const sortedItems = products.filter((item) => item.id !== 99);
      if (sortedItems) {
        setSelectedItems(sortedItems);
      }
    }
  };

  const showFilterItem = (e) => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };
  const handleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedItem(selectedId);
    changeItems(selectedId);
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
        }}
        className="flex flex-col min-h-screen"
      >
        <div className="all-product-content ">
          <Header />
          <ToolbarSecond></ToolbarSecond>

          <hr className="all-product-header"></hr>
          <div className="all-product-main">
            <div className="all-filter-container">
              <div className="all-product-sidebar">
                <h3 className="all-product-sidebar-h3">Сонголт</h3>
              </div>
              <h3 className="all-product-sidebar-secondary">
                Хэрэглээний төрлөөр
              </h3>
              <hr></hr>
              {filterMenuItem.map((item, index) => (
                <div className="all-product-sidebar-item">
                  <div
                    onClick={() => changeItems(item.id)}
                    className="all-product-sidebar-item-2"
                  >
                    <span>{item.name}</span>
                    <div className="all-product-sidebar-item-img">
                      <img
                        src={ic_down}
                        className="starlink-icon"
                        alt="StarlinkGen3"
                      />
                    </div>
                  </div>
                  {selectedItemId === item.id && (
                    <div className="margin-left-20">
                      <Radio.Group
                        onChange={onRadioChange}
                        value={selectedRadioValue}
                      >
                        {category[selectedItemId - 1].items.map(
                          (selectedItem, idx) => (
                            <Radio
                              key={idx}
                              value={selectedItem.id}
                              className="all-product-sidebar-item-radio"
                            >
                              {selectedItem.name}
                            </Radio>
                          )
                        )}
                      </Radio.Group>
                    </div>
                  )}
                </div>
              ))}

              <h3 className="all-product-header-2">Бүтээгдэхүүний төрлөөр</h3>
              <hr></hr>
              {filterMenuItem2.map((item, index) => (
                <div className="all-product-sidebar-item">
                  <div
                    onClick={() => changeType(item.id)}
                    className="all-product-sidebar-item-2"
                  >
                    <span>{item.name}</span>
                    <div className="all-product-sidebar-item-img"></div>
                  </div>
                </div>
              ))}
              <h3 className="all-product-header-2">Тэжээлийн төрлөөр</h3>
              <hr></hr>
              {[
                { id: 1, value: "12V систем" },
                { id: 2, value: "220V систем" },
              ].map((item, index) => (
                <div style={{ width: 300 }}>
                  <div
                    onClick={() => changeV(item.id)}
                    className="all-product-sidebar-item-2 "
                  >
                    <span>{item.value}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="all-filter-second">
              {filterMenuItem.map((item, index) => (
                <div className=" buy-menu-dropdown-container">
                  <select
                    className="buy-menu-dropdown"
                    value={selectedItem}
                    onChange={handleChange}
                  >
                    <option key={index} value={item.id}>
                      <div className="dropdown-option-content">
                        <div className="ic-45">
                          <img
                            src={item.img}
                            className="starlink-icon"
                            alt="StarlinkGen3"
                          />
                        </div>
                        <span className="margin-left-20">{item.name}</span>
                      </div>
                    </option>
                  </select>
                  {selectedItemId === item.id && (
                    <div className=" buy-menu-dropdown-container">
                      <select
                        className="buy-menu-dropdown"
                        value={selectedItem}
                        onChange={handleChange}
                      >
                        {category[selectedItemId - 1].items.map(
                          (selectedItem, idx) => (
                            <option key={idx} value={selectedItem.id}>
                              <div className="dropdown-option-content">
                                <div className="ic-45">
                                  <img
                                    src={selectedItem.img}
                                    className="starlink-icon"
                                    alt="StarlinkGen3"
                                  />
                                </div>
                                <span className="margin-left-20">
                                  {selectedItem.name}
                                </span>
                              </div>
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="all-product-main-content">
              <div className="all-product-main-content-header">
                <span>Нийт бүтээгдэхүүн: {products.length}</span>
                <div onClick={showFilterItem} className="filter-button">
                  Эрэмбэ:  Санал болгосон
                </div>
                {showFilter && (
                  <div className="buy-main-filter-item">
                    {mainfilter.map((item, index) => (
                      <div
                        key={index}
                        className={`buy-main-filter-item-l ${
                          isSelected === index ? "selected" : ""
                        }`}
                        onClick={() => setSelectMainFilter(index)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="all-product-grid-2">
                <div className="all-product-grid">
                  {selectedItems &&
                    selectedItems.map((item, index) => (
                      <div
                        key={index}
                        className="buy-filter-item"
                        onClick={() => navigate(`/buy/${item.id}`)}
                      >
                        <div className="buy-filter-item-img">
                          <img
                            className="starlink-icon"
                            src={item.img}
                            alt="StarlinkGen3 Small"
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                          />
                        </div>
                        <span className="all-product-item-name">
                          {item.name}
                        </span>
                        <span className="all-product-item-price">
                          MNT {item.price.toLocaleString()}
                        </span>
                      </div>
                    ))}
                </div>
                <div style={{ height: 180 }}></div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f7f9fa",
          }}
          className="buy-button-container "
        >
          <FooterL></FooterL>
        </div>
      </div>
    </div>
  );
};

export default AllProduct;
