import React, { useState } from "react";
import fb_black from "../../assets/icons/7_black.png";
import b_long from "../../assets/black_logo_long.png";
import insta_black from "../../assets/icons/8_black.png";
import { useNavigate } from "react-router-dom";

const Faq = () => {
 
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const showItem = (index) => {
    setSelectedItemIndex(selectedItemIndex === index ? null : index);
  };
  const [isVisible, setIsVisible] = useState(false);
  const handleFbClick = () => {
    window.open("https://www.facebook.com/starlinknomad", "_blank");
  };
  const handleInstaClick = () => {
    window.open("https://www.instagram.com/starlinknomad/", "_blank");
  };

  const callInstruction = () => {
    if (isVisible === true) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const navigate = useNavigate();
  return (
    <div className="faq2-section">
      <h3 className="faq2-h3">ТҮГЭЭМЭЛ АСУУЛТУУД</h3>
      <div className="faq2-container">
        <div
          className={`paragraph ${selectedItemIndex === 1 ? "clicked" : ""
            }`}
          onClick={() => showItem(1)}
        >
          <div key={1} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 1 ? "clicked" : ""
                }`}
            >
              <span>Төхөөрөмжийг анх аваад хэрхэн идэвхжүүлэх вэ?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 1 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 1 ? "clicked" : ""
                }`}
            >
              <p key={1} className="faq2-p">
                Идэвхжүүлэх зааврыг та <a style={{ textDecoration: "underline", marginRight: 5, marginLeft: 5 }} onClick={() =>
                  window.open("./pdf/MobileBurtgel-StarlinkNomad.pdf")
                }> энд </a> дарж татаж авна уу.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 2 ? "clicked" : ""
            }`}
          onClick={() => showItem(2)}
        >
          <div key={2} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 2 ? "clicked" : ""
                }`}
            >
              <span>StarlinkNomad төхөөрөмж нь хаана ч сүлжээ барих уу? Монголын утасны операторуудын сүлжээтэй хамааралтай юу?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 2 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 2 ? "clicked" : ""
                }`}
            >
              <p key={2} className="faq2-p">
                Энэхүү төхөөрөмж нь Монгол орны газар нутаг болон Дэлхийн хаанаас ч сансрын хиймэл дагуулуудтай холбогдож хэзээ ч хаана ч интернэтэд холбогдох боломжтой юм. Монголын гар утасны операторуудын сүлжээтэй огт хамааралгүй буюу утасны сүлжээгүй болон сүлжээтэй ямар ч газар ажиллана.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 3 ? "clicked" : ""
            }`}
          onClick={() => showItem(3)}
        >
          <div key={3} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 3 ? "clicked" : ""
                }`}
            >
              <span>StarlinkNomad -р гар утасны дугаар руу ярьж болох уу?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 3 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 3 ? "clicked" : ""
                }`}
            >
              <p key={3} className="faq2-p">
                Старлинк Номад төхөөрөмжөөр нэгэнт өндөр хурдны интернэтэд холбогдсон бол харилцаа холбооны бүх шийдлийг ашиглаж болно. Та гар утсан дээрээ IP дугаарын аппликэшн суулгаснаар ямар ч операторын энгийн дугаар руу шууд дуудлага хийж дуудлага хүлээж авч болно. Жишээ нь: LIME – Виртуал оператор апп.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 4 ? "clicked" : ""
            }`}
          onClick={() => showItem(4)}
        >
          <div key={4} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 4 ? "clicked" : ""
                }`}
            >
              <span>Төхөөрөмжөө худалдаж авсны дараа сар тутмын төлбөр төлдөг үү?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 4 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 4 ? "clicked" : ""
                }`}
            >
              <p key={4} className="faq2-p">
                250,000₮ ийн төлбөрөөр нэг сарын турш хязгааргүй дата хэрэглээг авна. Төлбөр нь дараа төлбөрт зарчимтай.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 5 ? "clicked" : ""
            }`}
          onClick={() => showItem(5)}
        >
          <div key={5} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 5 ? "clicked" : ""
                }`}
            >
              <span>Төхөөрөмжөө түр ашиглахгүй бол төлбөрөө зогсоож болох уу?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 5 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 5 ? "clicked" : ""}`}
            >
              <div key={5} className="faq2-p">Болно.
                <a
                  href="https://www.starlink.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: 5, marginRight: 5 }}
                >
                  www.starlink.com
                </a>
                дээр өөрийн бүртгэл нууц үгээр нэвтэрч PAUSE дарж түр зогсоож болно. Түр зогсолтын хугацаа 
              </div>
              <p className="faq2-p">хязгааргүй ба та хүссэн үедээ буцаан идэвхжүүлж болно. Зааврыг эндээс татаж авна уу.</p>
            </div>

          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 6 ? "clicked" : ""
            }`}
          onClick={() => showItem(6)}
        >
          <div key={6} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 6 ? "clicked" : ""
                }`}
            >
              <span>Төлбөрөө хэнд, хаана төлөх вэ?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 6 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 6 ? "clicked" : ""
                }`}
            >
              <p key={6} className="faq2-p">
                Aнх төхөөрөмжийг идэвхжүүлэхдээ <a href="www.starlink.com">www.starlink.com</a> дээр эзэмшигчийн нэр дээр бүртгэл үүсгэх ба уг явцад олон улсын төлбөрийн карт холбож өгдөг. Уг картаас эхний сарын төлбөр татагдах ба цаашид уг картаас сар бүрийн төлбөр автоматаар татагдах болно. Төлбөрийг манай компани биш АНУ-ын Starlink компани авч байгаа болно.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 7 ? "clicked" : ""
            }`}
          onClick={() => showItem(7)}
        >
          <div key={7} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 7 ? "clicked" : ""
                }`}
            >
              <span>Сар бүрийн төлбөртөө НӨАТ авч болох уу?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 7 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 7 ? "clicked" : ""
                }`}
            >
              <p key={7} className="faq2-p">
                Болно. Старлинкын манай улс дахь төлөөлөгч Старлинк Сервис Монголиа ХХК руу 7777777 дугаарт залгаж НӨАТ – ын баримтаа авч болно. Та анх идэвхжүүлэх явцад VAT гэсэн форм дээр өөрийн эсвэл байгууллагын регистр дугаараа оруулсан байх ёстой.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 8 ? "clicked" : ""
            }`}
          onClick={() => showItem(8)}
        >
          <div key={8} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 8 ? "clicked" : ""
                }`}
            >
              <span>Старлинк Номад төхөөрөмжийг зөвхөн Монголдоо л хэрэглэх боломжтой юу? Өөр улс оронд хэрэглэж болох уу?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 8 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 8 ? "clicked" : ""
                }`}
            >
              <p key={8} className="faq2-p">
                Старлинк Номад төхөөрөмж нь одоогоор Дэлхийг тойрон ажиллаж буй 5874+ хиймэл дагуулаас сүлжээ авдаг тул цөөн хэдэн ашиглах боломжгүй улсуудаас бусад дэлхийн хаана ч ашиглах боломжтой.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 9 ? "clicked" : ""
            }`}
          onClick={() => showItem(9)}
        >
          <div key={9} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 9 ? "clicked" : ""
                }`}
            >
              <span>Хил орчмын бүсэд ажиллах уу?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 9 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 9 ? "clicked" : ""
                }`}
            >
              <p key={9} className="faq2-p">
                Манай улсын хөрш орнууд БНХАУ, ОХУ -ууд старлинкын сүлжээг нутаг дэвсгэр дээрээ зөвшөөрөөгүй байгаа тул уг хориотой холбоотойгоор манай улсын хил орчмын хэсгээр төхөөрөмж ажиллахгүй. Бидний туршилтаар хилийн зурвасаас 2 ~ 30км хэлбэлзэлтэй зайд тасарч байсан. www.starlink.com/maps эндээс та хамрах хүрээг харж болно.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 10 ? "clicked" : ""
            }`}
          onClick={() => showItem(10)}
        >
          <div key={10} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 10 ? "clicked" : ""
                }`}
            >
              <span>Старлинк Номад хөдөлгөөнт төхөөрөмжийг суурин ашиглаж болох уу?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 10 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 9 ? "clicked" : ""
                }`}
            >
              <p key={10} className="faq2-p">
                Болно. Манай төхөөрөмж нь цахилгаан тэжээлийн бүх боломжийг дэмждэг буюу 12в, 24в, 220в -р ажиллах тул та эдгээр төрлийн цахилгаан тэжээлтэй хаана ч суурин байдлаар ашиглаж болно.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 11 ? "clicked" : ""
            }`}
          onClick={() => showItem(11)}
        >
          <div key={9} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 11 ? "clicked" : ""
                }`}
            >
              <span>Старлинк Номад хөдөлгөөнт төхөөрөмжийн цахилгаан зарцуулалт ямар байдаг вэ?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 11 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 11 ? "clicked" : ""
                }`}
            >
              <p key={11} className="faq2-p">
                Манай төхөөрөмжүүд нь автомашины баттерей болон бусад 12в тогтмол хүчдэлийн эх үүсвэрээс ажилладаг ба StarlinkNomad Gen2 цагт дунджаар 60 ватт, StarlinkNomad Gen3 цагт дунджаар 90 ватт цахилгаан зарцуулна.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 12 ? "clicked" : ""
            }`}
          onClick={() => showItem(12)}
        >
          <div key={12} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 12 ? "clicked" : ""
                }`}
            >
              <span>StarlinkNomad – Явдаг интернэт нь Старлинкын Flat High Performance явуулын загвараас юугаараа яалгаатай вэ? Давуу, тал нь юу вэ?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 12 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 12 ? "clicked" : ""
                }`}
            >
              <p key={12} className="faq2-p">
                StarlinkNomad төхөөрөмж нь Монгол орны эрс тэс уур амьсгал цаг агаарын хүнд нөхцөлд зориулан бат бэх байдлаар шийдэж ашиглахад хялбар байхаар бүгд нэг хайрцагт багтааж хялбаршуулсан Монгол залуусын шийдлээр угсарсан төхөөрөмж юм
                Flat high Performance нь өндөр үнэтэй, зөвхөн 220В-р ажиллах ба хэмжээ илүү том, жин илүү хүнд мөн 3 төхөөрөмжийг угсарч холбож байж ажилладаг механик ажиллагаа ихтэй. Сарын төлбөр 950.000 мөртөө зөвхөн дата хязгаартай багцын үйлчилгээг дэмждэг. Машин дээр байрлуулахад тохиромж муутай шийдэлтэй, WiFi цацагч роутер нь 5-р үеийнх байдаг бол Starlinк Nomad-ыг 6-р үеийн WiFi цацагчаар сайжруулж угсарсан тул илүү их талбайд сүлжээгээ цацдаг.
                StarlinkNomad нь StarlinkStandart төхөөрөмжийг ашиглан өөрчилж сайжруулж хийсэн тул Flat High Performance -с үнийн хувьд хямд болж чадсан. Ажиллах зарчим, интернэтийн хурд зэрэг нь ялгаагүй. StarlinkNomad нь стандарт загварын сарын хураамж буюу 250,000₮ -р хязгааргүй дата багцыг дэмжиж ажилладгаараа давуу юм.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
        <div
          className={`paragraph ${selectedItemIndex === 13 ? "clicked" : ""
            }`}
          onClick={() => showItem(13)}
        >
          <div key={13} className="faq2-li">
            <h5
              className={`faq2-q ${selectedItemIndex === 13 ? "clicked" : ""
                }`}
            >
              <span>StarlinkStandart Gen3 буюу суурин загвар болон StarlinkStandart Gen2 суурин загвар хоёрын ялгаа юу вэ?</span>
            </h5>
            <div style={{ height: 20, width: 20, marginLeft: 60 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                alt="i1"
                src="https://i0.wp.com/monasteryeventcenter.com/wp-content/uploads/2018/12/white-down-arrow-png-2.png?ssl=1"
              ></img>
            </div>
          </div>
          {selectedItemIndex === 13 && (
            <div
              className={`faq2-desc ${selectedItemIndex === 13 ? "clicked" : ""
                }`}
            >
              <p key={13} className="faq2-p">
                StarlinkStandart Gen3 нь старлинкээр хамгийн сүүлд гаргасан шинэ загвар ба Манай улс болон дэлхийн ихэнх улс оронд албан ёсоор борлуулж эхлээгүй байгаа юм. Техник үзүүлэлтийн хувьд өмнөх Gen2 загвараас 30%-р сүлжээний хурд, тогтвортой байдлаар илүү. Сүлжээнд холбогдох хурд өмнөх загвараас 5 дахин хурдан. Gen2 загвар нь WiFi  цацагч роутер нь 5-p үеийнх бол Gen3-ын 6-р үеийн цацагч илүү өргөн талбайд цацах чадвартай
                Gen2 утастай сүлжээний LAN портгүй тул заавал нэмэлт адаптер худалдаж авч холбох шаардлага гардаг байсан бол Gen3 загвар 2 ширхэг LAN порттой юм.
              </p>
            </div>
          )}
          <hr
            style={{
              color: "white",
              display: "flex",
              margin: "0px auto",
            }}
          />
        </div>
      </div>
      <div className="faq2-footer">
        <div className="faq2-footer-container" style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: 20,
            }}
          >
            <div style={{ height: 15 }}>
              <img
                style={{ height: "100%", width: "100%" }}
                src={b_long}
                alt="logo"
              />
            </div>
            <div
              className="faq2-footer-text margin-left-28"
              onClick={() => navigate(`/home?id=${"starlink3"}`)}
            >
              Түгээмэл асуултууд
            </div>
            <div
              className="faq2-footer-text  margin-left-28"
              onClick={() => navigate("/contact")}
            >
              Холбоо барих
            </div>
            <div
              className="faq2-footer-text  margin-left-28"
              onClick={callInstruction}
            >
              Заавар татах
            </div>
            {isVisible && (
              <>
                <div
                  className="faq2-footer-text margin-left-28"
                  onClick={() =>
                    window.open("/pdf/MobileBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Хөдөлгөөнт идэвхжүүлэх
                </div>
                <div
                  className="faq2-footer-text margin-left-28"
                  onClick={() =>
                    window.open("/pdf/SuurinBurtgel-StarlinkNomad.pdf")
                  }
                >
                  Суурин идэвхжүүлэх
                </div>
              </>
            )}
          </div>
          <div className="faq2-footer-contact">
            <img src={fb_black} alt="facebook" onClick={handleFbClick} />
            <img
              src={insta_black}
              alt="instagram"
              style={{ marginLeft: 5 }}
              onClick={handleInstaClick}
            />
          </div>
        </div>
        <div>
          <hr
            style={{
              color: "#a3abb1",
              margin: "0px auto",
              justifyContent: "center",
              width: 1400,
              height: "1px",
            }}
          />
        </div>
        <div className="faq2-footer-container" style={{ marginTop: -10 }}>
          <div className="faq2-footer-text">
            Улаанбаатар хот, Сүхбаатар дүүрэг 3-р хороо, Royal Tower, 503
            тоот(Циркийн урд)
          </div>
          <div className="faq2-footer-text faq-number">+976 75990033</div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
