import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import gen3_1 from "../assets/gallery-img/gen3_1.png";
import gen3_3 from "../assets/gallery-img/gen3_3.png";
import gen3_4 from "../assets/gallery-img/gen3_4.jpg";
import gen3_5 from "../assets/gallery-img/gen3_5.jpg";
import gen3_6 from "../assets/gallery-img/gen3_6.png";

import gen2_1 from "../assets/gallery-img/gen2_1.png";
import gen2_2 from "../assets/gallery-img/gen2_2.jpg";
import gen2_3 from "../assets/gallery-img/gen2_3.jpg";
import gen2_4 from "../assets/gallery-img/gen2_4.jpg";
import gen2_5 from "../assets/gallery-img/gen2_5.jpg";
import gen2_6 from "../assets/gallery-img/gen2_6.jpg";
import gen2_7 from "../assets/gallery-img/gen2_7.png";
import gen2_8 from "../assets/gallery-img/gen2_8.png";
import gen2_9 from "../assets/gallery-img/gen2_9.png";

import battery_0 from "../assets/gallery-img/battery_0.png";
import battery_1 from "../assets/gallery-img/battery_1.jpg";
import battery_2 from "../assets/gallery-img/battery_2.jpg";
import battery_3 from "../assets/gallery-img/battery_3.jpg";

import sbattery_1 from "../assets/gallery-img/sbattery_1.jpg";
import sbattery_2 from "../assets/gallery-img/sbattery_2.png";
import sbattery_3 from "../assets/gallery-img/sbattery_3.png";

import st_1 from "../assets/others/standard_gen3.webp";
import st_2 from "../assets/gallery-img/st_1.png";
import st_4 from "../assets/gallery-img/st_3.png";
import st_5 from "../assets/gallery-img/st_4.png";
import st_6 from "../assets/gallery-img/st_5.png";
import st_7 from "../assets/gallery-img/st_6.png";
import st_8 from "../assets/gallery-img/st_7.png";

import wf_1 from "../assets/others/outdoor_wifi6.png";
import starlink_carry_bag from "../assets/others/starlink_carry_bag.png";
import gen2suuri from "../assets/additional/Gen2 suuri 2.png";
import tolgoi from "../assets/additional/tawag 2.png";
import holbohturba from "../assets/additional/turba2 2.png";
import set from "../assets/additional/SET.png";
import tolgoi_use from "../assets/additional/tolgoi_use.png";
import tolgoi_use2 from "../assets/additional/tolgoi_use2.png";
import g45 from "../assets/additional/Gradus 1.png";
import g2 from "../assets/additional/gradius 2.png";
import set2 from "../assets/additional/SET 2.png";
import behleh from "../assets/additional/suuri 2.png";
import gen2suuri2 from "../assets/additional/gen2suuri.png";
import gen3_achaa_suuri from "../assets/gallery/gen3_achaa_suuri.png";
import gen23 from "../assets/additional/Gen 2 3.png";
import indoor_wifi6 from "../assets/others/indoor_wifi6.png";
import outdoor_wifi_small from "../assets/others/outdoor_wifi_small.png";
import outdoor_wifi6_small from "../assets/others/outdoor_wifi6_small.png";
import indoorwifi67 from "../assets/additional/indoorwifi6.png";
import car_charger_cable from "../assets/others/car_charger_cable.png";
import solar_panel from "../assets/others/solar_panel.png";
import solar_panel_unfolded from "../assets/others/solar_panel_unfolded.png";
import charger_220 from "../assets/others/220charger.png";
import salaalagch_12v from "../assets/additional/12v_salaalagch.png";
import zalguur_12V from "../assets/additional/12v_zalguur.png";
import inverter_220 from "../assets/additional/220v_inverter.png";
import inverter from "../assets/additional/inverter.png";
import zalguur_220 from "../assets/additional/220v_zalguur.png";
import acc_zalguur from "../assets/additional/Acc_zalguur.png";
import soronzon_suuri from "../assets/additional/soronzon_suuri.png";
import zalguur from "../assets/additional/zalguur.png";
import regulator from "../assets/additional/regulator.png";
import regulator2 from "../assets/additional/regulator2.jpg";
import connector_30 from "../assets/additional/30_connector.png";
import connector_1 from "../assets/additional/connector1.jpg";
import connector_4 from "../assets/additional/connector4.jpg";
import extender1 from "../assets/additional/extender1.jpg";
import extender3 from "../assets/additional/extender3.jpg";
import suuri_gen3 from "../assets/additional/SET.png";
import suuri_gen31 from "../assets/additional/SET 2.png";

const items = [
  { id: 1, images: [gen3_1, gen3_5, gen3_3, gen3_4, gen3_6] },
  { id: 2, images: [battery_0, battery_3, battery_1, battery_2] },
  {
    id: 3,
    images: [ st_6, st_5, st_4],
  },
  { id: 4, images: [wf_1] },
  {
    id: 5,
    images: [gen2_1, gen2_2, gen2_3, gen2_4, gen2_5, gen2_6, gen2_7, gen2_8],
  },
  { id: 6, images: [sbattery_1, sbattery_2, sbattery_3] },
  {
    id: 7,
    images: [starlink_carry_bag, gen2_9],
  },
  { id: 8, images: [gen3_achaa_suuri, gen2suuri2, gen23] },
  { id: 9, images: [outdoor_wifi6_small] },
  { id: 10, images: [outdoor_wifi_small] },
  { id: 11, images: [indoor_wifi6, indoorwifi67] },

  { id: 12, images: [car_charger_cable] },

  { id: 13, images: [gen3_1, gen3_5, gen3_3, gen3_4, gen3_6] },
  { id: 14, images: [solar_panel, solar_panel_unfolded] },
  { id: 15, images: [charger_220] },
  { id: 16, images: [salaalagch_12v] },
  { id: 17, images: [zalguur_12V] },
  { id: 18, images: [inverter,inverter_220] },
  { id: 19, images: [zalguur_220] },
  { id: 20, images: [acc_zalguur] },
  { id: 21, images: [soronzon_suuri] },
  { id: 22, images: [zalguur] },
  { id: 23, images: [zalguur] },
  { id: 24, images: [zalguur] },
  { id: 25, images: [gen2suuri, gen2suuri2] },
  { id: 26, images: [tolgoi, set, st_4, tolgoi_use, tolgoi_use2, set2] },
  { id: 27, images: [holbohturba, set, st_4, tolgoi_use, tolgoi_use2, set2] },
  { id: 28, images: [g45, g2, set, st_4, tolgoi_use, tolgoi_use2, set2] },
  { id: 29, images: [g45, g2, set, st_4, tolgoi_use, tolgoi_use2, set2] },
  { id: 30, images: [behleh, g2, set, st_4, tolgoi_use, tolgoi_use2, set2] },
  { id: 31, images: [starlink_carry_bag, gen2_9] },
  { id: 32, images: [regulator, regulator2] },
  { id: 33, images: [connector_30,connector_1, connector_4] },
  { id: 34, images: [extender3, extender1,connector_4] },
  { id: 35, images: [suuri_gen3, suuri_gen31] },
];

const Gallery = ({ item }) => {
  const id = parseInt(item);
  const [itemss, setItems] = useState([]);

  useEffect(() => {
    console.log(items[0].id);
    const filteredItems = items.filter(
      (selectedItem) => selectedItem.id === id
    );
    if (filteredItems.length > 0) {
      setItems(filteredItems[0].images);
    }
  }, [id]);

  return (
    <div>
      <Carousel style={{ width: "100%", height: "100%" }}>
        {itemss.map((image, idx) => (
          <Carousel.Item key={idx}>
            <img
              className="d-block w-100"
              src={image}
              alt={`Slide ${idx}`}
              style={{ maxHeight: 620, width: 750 }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Gallery;
